import React, {useState} from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';

export default function FaqAccordian() {
  const [open, setOpen] = useState(6);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <>
      <Accordion open={open === 6}>
        <AccordionHeader
          onClick={() => handleOpen(6)}
          className="accordion-header"
        >
                How do I get weights into my account?
        </AccordionHeader>
        <AccordionBody className="accordion-body">
          {'You must connect your Fitbit account to your WeightSync account. ' +
              'Once you have done that, your weights will be synced automatically' +
            ' (as long as "push" notifications are enabled on your account page). ' +
            'Push notifications are enabled by default, so if you haven\'t disabled anything, ' +
            'you should be good to go.'}
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 1}>
        <AccordionHeader
          onClick={() => handleOpen(1)}
          className="accordion-header"
        >
          Why is the site free?
        </AccordionHeader>
        <AccordionBody className="accordion-body">
          {'The site is free because I can\'t offer a level of service that would justify charging for it.' +
              ' I built this site because I wanted to learn how to build a full stack web app, and I wanted to solve a problem that I had.' +
              ' If other people can get some value for it then that\'s great.'}
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 2}>
        <AccordionHeader
          onClick={() => handleOpen(2)}
          className="accordion-header"
        >
          Why do you need my Garmin login credentials?
        </AccordionHeader>
        <AccordionBody className="accordion-body">
          {'We need your Garmin login credentials so that I can log in to your account and sync your weights.' +
                'Garmin doesn\'t have an API for weight, so this is the only way to do it.'}
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 3}>
        <AccordionHeader
          onClick={() => handleOpen(3)}
          className="accordion-header"
        >
          How do you protect my Garmin login credentials?
        </AccordionHeader>
        <AccordionBody className="accordion-body">
          {'Your Garmin login credentials are encrypted using RSA-2048 public key encryption. ' +
              'The decryption key is stored on Google Secrets Manager, ' +
              'and is only accessible by the non-publicly accessible backend server.'}
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 7}>
        <AccordionHeader
          onClick={() => handleOpen(7)}
          className="accordion-header"
        >
          Do you support Garmin 2FA/verification codes?
        </AccordionHeader>
        <AccordionBody className="accordion-body">
          {'Not currently, but this could be added in the future if there is demand for it.'}
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 4}>
        <AccordionHeader
          onClick={() => handleOpen(4)}
          className="accordion-header"
        >
                How do you protect my Wahoo SYSTM login credentials?
        </AccordionHeader>
        <AccordionBody className="accordion-body">
          {'See the Garmin answer above, the SYSTM credentials are encrypted in the same way.'}
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 8}>
        <AccordionHeader
          onClick={() => handleOpen(8)}
          className="accordion-header"
        >
          My weight hasn&apos;t shown up in my Garmin account, what do I do?
        </AccordionHeader>
        <AccordionBody className="accordion-body">
          {'The Garmin weight upload process can be a little flaky. ' +
            'As long as you are confident that the username and password you entered are correct, ' +
            'and your account doesn\'t have 2FA enabled (you need receive a code by email or SMS), ' +
            'then the weight should show up eventually. ' +
            'Any failed upload attempts will be added to a queue and retried every hour, on the hour.'}
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 5}>
        <AccordionHeader
          onClick={() => handleOpen(5)}
          className="accordion-header"
        >
                I found a bug, how do I report it?
        </AccordionHeader>
        <AccordionBody className="accordion-body">
          {'This site is still a work in progress, so there are bound to be bugs. If you find one, please email me at '}
          <a href="mailto:admin@weightsync.org">admin@weightsync.org</a>
          {'.'}
        </AccordionBody>
      </Accordion>
    </>
  );
}
