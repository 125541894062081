import React from 'react';
import Chart from 'react-apexcharts';
import {useState, useEffect} from 'react';
import {backendUrl} from '../App';
import {sortArray} from '../utils/dataUtils';
import {makeAuthorizedGet} from '../utils/credentials';

const WeightChartApex = () => {
  const sortBy = 'timestamp';
  const sortDesc = true;
  const [weightData, setWeightData] = useState([]);

  const getWeightData = async () => {
    const data = await makeAuthorizedGet(backendUrl + '/profile/weight-data?limit=100');
    const weightDataTimestamp = data.data.data.map((data) => {
      return {
        ...data,
        timestamp: Date.parse(data.date),
      };
    });
    const weightDataSorted = sortArray(sortBy, weightDataTimestamp, sortDesc);
    const weightDataWithTimestamp = {data: weightDataSorted, timestamp: Date.now()};
    localStorage.setItem('weightDataPlot', JSON.stringify(weightDataWithTimestamp));
  };

  useEffect(() => {
    if (weightData.length === 0) {
      const fetchOrReadData = async () => {
        let weightDataSorted;
        let weightDataWithTimestamp;
        if (localStorage.getItem('weightDataPlot') === null) {
          await getWeightData();
          weightDataWithTimestamp = JSON.parse(localStorage.getItem('weightDataPlot'));
          weightDataSorted = weightDataWithTimestamp.data;
        } else {
          weightDataWithTimestamp = JSON.parse(localStorage.getItem('weightDataPlot'));
          weightDataSorted = weightDataWithTimestamp.data;
          const timeSinceLastFetch = Date.now() - weightDataWithTimestamp.timestamp;
          if (timeSinceLastFetch > 3600000) {
            await getWeightData();
            weightDataWithTimestamp = JSON.parse(localStorage.getItem('weightDataPlot'));
            weightDataSorted = weightDataWithTimestamp.data;
          }
        }
        setWeightData(sortArray(sortBy, weightDataSorted, sortDesc));
      };
      fetchOrReadData();
    }
  }, []);

  const minYVal = () => {
    const minVal = Math.min(...weightData.map((data) => data.weight));
    return minVal - 0.5;
  };
  const options = {
    series: [
      {
        name: 'Weight',
        data: weightData.map((data) => {
          return [data.date, data.weight];
        }),
      },
    ],
    chart: {
      redrawOnWindowResize: true,
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
      },
    },
    markers: {
      fillColor: '#e3e3e3',
      strokeColor: '#fff',
      size: 2,
      shape: 'circle',
      strokeWidth: 0.5,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'nofill',
      colors: ['transparent'],
    },
    yaxis: {
      min: minYVal,
      title: {
        text: 'Weight',
      },
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      shared: false,
      x: {
        format: 'dd MMM yyyy',
      },
    },
  };
  return (
    <div className="weight-chart-page">
      <h1 style={{textAlign: 'center'}}>Weight Chart</h1>
      <Chart
        options={options}
        series={options.series}
        type={options.chart.type}
        height={350}
      />
    </div>
  );
};

export default WeightChartApex;
