import {storeBearerInSession} from './credentials';
import {backendUrl} from '../App';
import {makeAuthorizedGet} from './credentials';

export default async function processLogin(credentialResponse) {
  const token = credentialResponse.credential;
  storeBearerInSession(token);
  const response = await makeAuthorizedGet(backendUrl + '/google', false);
  localStorage.setItem('user-info', JSON.stringify(response.data));
  return response;
}
