import React from 'react';
// import processLogin from '../utils/authFunctions';
import {makeAuthorizedGet} from '../utils/credentials';
import {backendUrl} from '../App';
import useAuth from '../utils/useAuth';
import axios from 'axios';
import {useEffect} from 'react';


export default function GoogleLoginFromStatic() {
  const {login} = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/bearer-from-cookie`, {withCredentials: true});
        const googleCode = response.data;
        sessionStorage.setItem('bearer', googleCode);
        const googleResponse = await makeAuthorizedGet(backendUrl + '/google', false);
        if (googleResponse.status !== 200) {
          localStorage.setItem('user-info', JSON.stringify(googleResponse.data));
          login();
          await makeAuthorizedGet(`${backendUrl}/profile/get-or-create-user`);
          setTimeout(() => {
            window.location.href = '/profile';
          }, 1000);
        }
      } catch (error) {
        console.error('Error fetching bearer from cookie:', error);
        // Handle the error or show an error message to the user
      }
    };
    fetchData(); // Call the function once the component is mounted
  }, [login]);

  // The rest of your component's JSX and logic
  return <></>;
}

