import React from 'react';


function About() {
  return <div className={'about-page'}>
    <div className={'about-content'}>
      <div className="about flex flex-col py-4 px-4 justify-center">
        <h1 className="about-header">About</h1>
        <p className="about-content">
            This is a simple web app that takes weights from a Fitbit account and
            syncs them to Garmin Connect, Strava, Runalyze, and Wahoo SYSTM.
          {' It is a work in progress, and is not affiliated with any of the companies mentioned above. It is similar to site smartscalesync, but its free, and always will be.'}
        </p>
        <h1 className="about-header">How it works</h1>
        <p className="about-content">
            The site uses the Fitbit API to get your weight data, and the Garmin, Strava, Runalyze, and Wahoo SYSTM APIs to sync your weight data to those services.
            The site uses a serverless backend, which means that the backend is hosted on Google Cloud Run, and is only spun up when it is needed.
            The backend is written in Python, and is responsible for syncing your weight data to the various services.
        </p>
        <h1 className="about-header">Source code</h1>
        <p className="about-content">
            The source code for the site is not currently available, but I plan to make it available in the future.
        </p>
        <h1 className="about-header">Privacy</h1>
        <div className="about-content">
            We securely store your Garmin and Wahoo SYSTM login credentials in an encrypted format, solely for the purpose of syncing your weight data. We also store your Fitbit and Strava authorization tokens, which we use to access your weight data. Additionally, we store your weight data in a database, so that it can be synced to the other services.
            We do not share your personal information with any third parties, except as required by law. We do not use cookies to track your online behavior.
            By using our site, you acknowledge and consent to the terms of this privacy policy. If you have any questions or concerns, please contact us.
        </div>
      </div></div>
  </div>;
}

export default About;

