import React, {useEffect, useState} from 'react';
import {parseDate, sortArray} from '../utils/dataUtils';
import {makeAuthorizedGet} from '../utils/credentials';
import {backendUrl} from '../App';
import SetUnauth from '../utils/useUnauthorized';

export default function WeightTable() {
  const [weightData, setWeightData] = useState([]);
  const [sortBy, setSortBy] = useState('timestamp');
  const [sortDesc, setSortDesc] = useState(true);
  // eslint-disable-next-line new-cap
  const {unauthorized} = SetUnauth();

  const makeGet = async (url) => {
    const response = await makeAuthorizedGet(url);
    if (response === 'UNAUTHORIZED') {
      unauthorized();
    }
    return response;
  };
  const getWeightData = async () => {
    const data = await makeGet(backendUrl + '/profile/weight-data?limit=20');
    const dataWithTimestamp = {data: data.data.data, timestamp: Date.now()};
    localStorage.setItem('weightData', JSON.stringify(dataWithTimestamp));
    return data.data.data;
  };

  useEffect(() => {
    const fetchWeightData = async () => {
      if (localStorage.getItem('weightData') === null) {
        return await getWeightData();
      } else {
        const dataWithTimestapm = JSON.parse(localStorage.getItem('weightData'));
        const data = dataWithTimestapm.data;
        const timeSinceLastFetch = Date.now() - dataWithTimestapm.timestamp;
        if (timeSinceLastFetch > 3600000) { // 1 hour
          return await getWeightData();
        } else {
          return data;
        }
      }
    };
    fetchWeightData()
        .then((data) => {
          setWeightData(data);
        },
        );
  }, []);

  useEffect(() => {
    setWeightData(sortArray(sortBy, weightData, sortDesc));
  }, [sortBy, sortDesc, weightData]);

  return (
    <>
      <div className="flex w-full flex justify-center items-center pt-10">
        <table className="sm:w-3/4 xs:w-5/6 text-sm text-left text-gray-500 dark:text-gray-400 text-center flex-col">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:text-gray-400">
            <tr>
              <th className="border-b font-medium p-4 pl-8 pt-3 pb-3 text-slate-400 border-l border-t">
                <div className="flex items-center justify-center">
                  Date
                  {/* eslint-disable-next-line */}
                  <a href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3 ml-1"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                      onClick={() => {
                        setSortBy('timestamp');
                        setSortDesc(!sortDesc);
                      }}
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                    </svg>
                  </a>
                </div>
              </th>
              <th className="border-b font-medium p-4 pl-8 pt-3 pb-3 text-slate-400 border-t">
                <div className="flex items-center justify-center">
                  Weight
                  {/* eslint-disable-next-line */}
                  <a href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3 ml-1"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                      onClick={() => {
                        setSortBy('weight');
                        setSortDesc(!sortDesc);
                      }}
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                    </svg>
                  </a>
                </div>
              </th>
              <th className="border-b font-medium p-4 pl-8 pt-3 pb-3 text-slate-400 border-t border-r">
                <div className="flex items-center justify-center">
                  Body Fat
                  {/* eslint-disable-next-line */}
                  <a href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3 ml-1"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                      onClick={() => {
                        setSortBy('fat');
                        setSortDesc(!sortDesc);
                      }}
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                    </svg>
                  </a>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {weightData.map((row, index) => (
              <tr key={index} className="border">
                <td className="border-b border-slate-100 p-4 pl-8 text-slate-500" data-cy="row-date">
                  {parseDate(row.timestamp)}
                </td>
                <td className="border-b border-slate-100 p-4 pl-8 text-slate-500" data-cy="row-weight">
                  {row.weight}
                </td>
                <td className="border-b border-slate-100 p-4 pl-8 text-slate-500" data-cy="row-fat">
                  {row.fat}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
