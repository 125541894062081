import React, {useEffect, useState} from 'react';
import {makeAuthorizedDelete, makeAuthorizedGet, makeAuthorizedPost} from '../utils/credentials';
import {backendUrl} from '../App';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {UnauthorizedContext} from '../utils/UnauthorizedContext';

const exampleSettings = {
  active: false,
  activeServices: {
    description: 'Automatic push updates are enabled for your fitbit account',
    button: 'Disable push updates',
  },
  inactiveServices: {
    description: 'Automatic push updates are disabled for your fitbit account',
    button: 'Enable push updates',
  },
};

const ProfileTable = (props) => {
  const notificationsEnabledReported =
    props.profileSettings.notificationsEnabled;
  const fitbitConnected = props.profileSettings.accountConnected;
  const [message, setMessage] = useState('');
  const [toastSuccess, setToastSuccess] = useState(null);
  const {setUnauthorized} = React.useContext(UnauthorizedContext);
  const [notificationsEnabled, setNotificationsEnabled] = useState(null);
  const [latestWeight, setLatestWeight] = useState(null);
  const [latestWeightDate, setLatestWeightDate] = useState(null);

  const makeGet = async (url) => {
    return await makeAuthorizedGet(url, setUnauthorized);
  };

  const notificationState = () => {
    if (notificationsEnabled === null) {
      return notificationsEnabledReported;
    } else {
      return notificationsEnabled;
    }
  };

  const handleManualSync = async () => {
    const response = await makeGet(`${backendUrl}/profile/sync-weights`);
    if (response.status === 200) {
      setMessage('Successfully synced weights');
      setToastSuccess(true);
    } else {
      setMessage('Failed to sync weights');
      setToastSuccess(false);
    }
  };
  const deactivatePushNotifications = () => {
    makeAuthorizedDelete(`${backendUrl}/profile/fitbit-push`)
        .then((response) => {
          if (response.status === 204) {
            setMessage('Successfully deactivated push notifications');
            setToastSuccess(true);
            setNotificationsEnabled(false);
          } else {
            setMessage('Failed to deactivate push notifications');
            setToastSuccess(false);
          }
        })
        .catch((error) => {
          setMessage('Failed to deactivate push notifications');
          setToastSuccess(false);
        });
  };
  const activatePushNotifications = () => {
    makeAuthorizedPost(`${backendUrl}/profile/fitbit-push`)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setMessage('Successfully activated push notifications');
            setToastSuccess(true);
            setNotificationsEnabled(true);
          } else {
            setMessage('Failed to activate push notifications');
            setToastSuccess(false);
          }
        })
        .catch((error) => {
          setMessage('Failed to activate push notifications');
          setToastSuccess(false);
        });
  };

  const fetchWeightData = async () => {
    const response = await makeAuthorizedGet(
        backendUrl + '/profile/weight-data?limit=1',
    );
    if (response.status === 200 && response.data.data.length > 0) {
      setLatestWeight(response.data.data[0].weight);
      const formattedDateWithTimeNoSeconds = (
        new Date(response.data.data[0].date).toLocaleDateString('en-GB') +
          ' ' + new Date(response.data.data[0].date).toLocaleTimeString(
            'en-GB', {hour: '2-digit', minute: '2-digit'}));
      setLatestWeightDate(formattedDateWithTimeNoSeconds);
    }
  };

  useEffect(() => {
    fetchWeightData();
  }, []);

  useEffect(() => {
    // const notifyPending = () => toast.info(message);
    const notifySuccess = () => toast.success(message);
    const notifyFailure = () => toast.error(message);
    if (message) {
      if (toastSuccess) {
        notifySuccess();
      } else {
        notifyFailure();
      }
      setMessage('');
    }
  }, [message]);

  return (
    <>
      <ToastContainer />
      <div className="flex justify-center items-center pt-10">
        <table className="table-auto sm:w-3/4 xs:w-full">
          <tbody>
            <tr>
              <td className="py-4 px-6">
                {latestWeightDate ?
                  `Most recent weight:  ${latestWeightDate}` :
                  'No data'}
              </td>
              <td className="py-4 px-6 text-center">
                {latestWeight ? `${latestWeight} kg` : null}
              </td>
            </tr>
            {fitbitConnected ? (
              <>
                <tr>
                  <td className="py-4 px-6">
                    {notificationState() ?
                      exampleSettings.activeServices.description :
                      exampleSettings.inactiveServices.description}
                  </td>
                  <td className="py-4 px-6">
                    {notificationState() ? (
                      <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-4 px-6 rounded w-full"
                        onClick={() => {
                          deactivatePushNotifications();
                        }}
                      >
                        Deactivate push notifications
                      </button>
                    ) : (
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-6 rounded w-full"
                        onClick={() => {
                          activatePushNotifications();
                        }}
                      >
                        Activate push notifications
                      </button>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-4 px-6">
                    If you wish to sync fitbit entries manually click here:
                  </td>
                  <td className="py-4 px-6">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded w-full"
                      onClick={() => {
                        handleManualSync();
                      }}
                    >
                      Sync manually
                    </button>
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ProfileTable;
