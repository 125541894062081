import React, {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {makeAuthorizedPost} from '../utils/credentials';
import {backendUrl} from '../App';

function ConnectFitbit() {
  const [searchParams] = useSearchParams();
  const fitbitCode = searchParams.get('code');

  useEffect(() => {
    (async function fetchData() {
      if (sessionStorage.getItem('fitbitCode') !== fitbitCode) {
        sessionStorage.setItem('fitbitCode', fitbitCode);
        const response = await makeAuthorizedPost(
            `${backendUrl}/profile/connect-fitbit?code=${fitbitCode}`,
        );
        if (response) {
          window.location.href = '/profile';
        }
      }
    })();
  }, [fitbitCode]);

  return <div className="h-screen flex"><div className={'mx-auto py-10'}>Processing code...</div></div>;
}

export default ConnectFitbit;
