import React from 'react';
import FaqAccordian from '../components/FaqAccordion';

export default function Faq() {
  return (
    <section className="faq-box">
      <FaqAccordian />
    </section>
  );
}
