import React from 'react';
import ShowGoogleLogin from '../utils/ShowGoogleLogin';
import LocalLogin from '../components/LocalLogin';
import oldScales from '../static/images/old-scales.jpg';
import stravaGrey from '../static/images/strava_grey.svg';
import fitbitLogo from '../static/images/works-with-fitbit.png';

export default function Home() {
  const APP_ENV = process.env.REACT_APP_ENV;
  if (APP_ENV !== 'prod') {
    console.log(`APP_ENV: ${APP_ENV}`);
  }
  return (
    <section className="home-page">
      <div className="flex flex-col">
        <div className="flex flex-col md:flex-row items-center">
          <div className="w-full md:w-1/2 two-col-home text-center justify-center flex flex-col items-center">
            <div className="text-4xl mb-4">WeightSync</div>
            <p>Sync weights from Fitbit to other services</p>
            <div className="flex items-center justify-center pt-5">
              <ShowGoogleLogin/>
            </div>
            {APP_ENV === 'local' ? <LocalLogin/> : null}
          </div>
          <div className="w-full md:w-1/2 two-col-home">
            <img src={oldScales} alt="Old scales"/>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center pt-5 w-full info-banner">
          <h1>What does this site do?</h1>
          <p className="justify-center pt-3">
          This site allows you to sync your fitbit weight data to other services. Currently we
          support Garmin, Strava, Wahoo (SYSTM/X), and Runalyze.
          </p>
        </div>
      </div>
      <footer className="w-full justify-center items-center bottom-copyright-bar">
        <img className="works-with-fitbit-logo" src={fitbitLogo} alt="Works with fitbit logo"/>
        <p>&copy; {new Date().getFullYear()} WeightSync</p>
        <img className="compatible-with-strava-logo" src={stravaGrey} alt="Compatible with strava logo"/>
      </footer>
    </section>
  );
}
