import {makeAuthorizedDelete, makeAuthorizedPost} from './credentials';
import {backendUrl} from '../App';

export function deactivateStrava() {
  window.location.href = 'https://www.strava.com/settings/apps';
}
export function deactivateFitbit() {
  window.location.href = 'https://www.fitbit.com/settings/applications';
}
export function deactivateRunalyze() {
  makeAuthorizedDelete(backendUrl + '/delete-runalyze-credentials');
}
export function deactivateGarmin() {
  makeAuthorizedDelete(backendUrl + '/delete-garmin-credentials');
}

export function deactivateSystm() {
  makeAuthorizedDelete(backendUrl + '/delete-systm-credentials');
}

export function activateGarmin() {
  makeAuthorizedPost(backendUrl + '/garmin-credentials');
}

export function deactivateTrainerRoad() {
  makeAuthorizedDelete(backendUrl + '/trainer-road-credentials');
}
