import React from 'react';
// import WeightChart from "../components/WeightChart";
import WeightChartApex from '../components/WeightChartApex';

function Visualiser() {
  return (
    <div>
      <WeightChartApex />
    </div>
  );
}

export default Visualiser;
