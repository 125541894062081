import React, {useState} from 'react';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'reactjs-popup/dist/index.css';
import {
  deactivateStrava,
  deactivateFitbit,
  deactivateGarmin,
  deactivateRunalyze,
  deactivateSystm,
  deactivateTrainerRoad,
} from '../utils/serviceActivation';
import {makeAuthorizedDelete} from '../utils/credentials';
import {backendUrl} from '../App';
import UsernamePasswordForm from './UsernamePasswordForm';
import {toast} from 'react-toastify';

function ServiceCard(props) {
  const [showPopup, setShowPopup] = useState(false);
  const {title, active, activeValues, inactiveValues, overrideConnectButton} = props;

  const notifyPending = (text) => toast.info(text);
  const notifySuccess = (text) => toast.success(text);
  const notifyFailure = (text) => toast.error(text);


  const processFitbit = () => {
    const FITBIT_CLIENT_ID = process.env.REACT_APP_FITBIT_CLIENT_ID;
    const FITBIT_REDIRECT_URI = process.env.REACT_APP_FITBIT_REDIRECT_URI;
    const FITBIT_EXPIRATION_IN_SECONDS =
      process.env.REACT_APP_FITBIT_EXPIRATION_IN_SECONDS;
    const FITBIT_URL = `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${FITBIT_CLIENT_ID}&redirect_uri=${FITBIT_REDIRECT_URI}&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=${FITBIT_EXPIRATION_IN_SECONDS}`;
    window.location.href = FITBIT_URL;
  };
  const processStrava = () => {
    const STRAVA_SCOPE = 'profile:read_all,profile:write';
    const STRAVA_REDIRECT_URI = process.env.REACT_APP_STRAVA_REDIRECT_URI;
    const STRAVA_CONNECT_URL = `https://www.strava.com/oauth/authorize?client_id=84884&redirect_uri=${STRAVA_REDIRECT_URI}&response_type=code&scope=${STRAVA_SCOPE}`;
    window.location.href = STRAVA_CONNECT_URL;
  };
  const resetTimers = async () => {
    return await makeAuthorizedDelete(`${backendUrl}/profile/reset-timers`);
  };
  const processAction = async (active, title, values) => {
    if (active) {
      const options = {
        message: `Are you sure you want to deactivate ${title}? You will have to reactivate it to use it again.`,
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              await resetTimers();
              if (title === 'Strava') {
                deactivateStrava();
              }
              if (title === 'Fitbit') {
                deactivateFitbit();
              }
              if (title === 'Garmin') {
                deactivateGarmin();
              }
              if (title === 'Runalyze') {
                deactivateRunalyze();
              }
              if (title === 'Wahoo SYSTM') {
                deactivateSystm();
              }
              if (title === 'TrainerRoad') {
                deactivateTrainerRoad();
              }
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => {},
        afterClose: () => {},
        onClickOutside: () => {},
        onKeypress: () => {},
        onKeypressEscape: () => {},
        overlayClassName: 'confirm-alert-overlay',
      };
      confirmAlert(options);
    }
  };
  const processConnectAction = (active, title, values) => {
    if (title === 'Garmin') {
      setShowPopup(true);
    } else if (title === 'Fitbit') {
      processFitbit();
    } else if (title === 'Runalyze') {
      setShowPopup(true);
    } else if (title === 'Strava') {
      processStrava();
    } else if (title === 'Wahoo SYSTM') {
      setShowPopup(true);
    } else if (title === 'TrainerRoad') {
      setShowPopup(true);
    }
  };
  return (
    <>
      <div className="bg-white rounded-lg shadow-md overflow-hidden justify-center items-center ServiceCardContainer">
        <div className="p-4 text-center ">
          <h2 className="text-lg font-semibold mb-2">{title}</h2>
          <p className="text-gray-700">
            {active ? activeValues.description : inactiveValues.description}
          </p>
          {active ? (
            <button
              onClick={() => processAction(active, title, activeValues)}
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 ServiceCardButton"
            >
              {activeValues.action}
            </button>
          ) : null}
          {!active && overrideConnectButton ? (
          <a className="cursor-pointer" onClick={() => {
            processConnectAction(active, title, inactiveValues);
          }}><img
              alt={`Connect to ${title}`}
              src={overrideConnectButton ? props.overrideConnectButtonImage : null}
              className="hover-lighten mt-5 px-4 ServiceCardButton"
            /></a>
            ) : null}
          {!active && !overrideConnectButton ? (
            <button
              onClick={() => {
                processConnectAction(active, title, inactiveValues);
              }}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 ServiceCardButton"
            >
              {inactiveValues.action}
            </button>
          ) : null}
        </div>
      </div>
      {showPopup ? (
        <UsernamePasswordForm
          setShowPopup={setShowPopup}
          service={title}
          notifyPending={notifyPending}
          notifySuccess={notifySuccess}
          notifyFailure={notifyFailure}
        />
      ) : null}
    </>
  );
}

export default ServiceCard;
