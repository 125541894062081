import {useContext} from 'react';
import {UnauthorizedContext} from './UnauthorizedContext';

function SetUnauth() {
  const {setUnauthorized} = useContext(UnauthorizedContext);

  function unauthorized() {
    setUnauthorized(true);
    // document.body.style.filter = "blur(5px)";
    // document.getElementById("overlay").style.display = "block";
  }

  return {unauthorized};
}

export default SetUnauth;
