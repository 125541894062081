import {useContext} from 'react';
import AuthContext from './AuthContext';

function useAuth() {
  const {setLoggedIn} = useContext(AuthContext);

  function login() {
    setLoggedIn(true);
  }

  function logout() {
    setLoggedIn(false);
    localStorage.removeItem('user');
    localStorage.removeItem('x-csrf-token');
    localStorage.removeItem('user-info');
    sessionStorage.clear();
    window.location.href = '/';
  }

  return {login, logout};
}

export default useAuth;
