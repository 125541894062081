import React, {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {makeAuthorizedPost} from '../utils/credentials';
import {backendUrl} from '../App';

function ConnectStrava() {
  const [searchParams] = useSearchParams();
  const stravaCode = searchParams.get('code');
  useEffect(() => {
    (async function fetchData() {
      if (sessionStorage.getItem('stravaCode') !== stravaCode) {
        sessionStorage.setItem('stravaCode', stravaCode);
        const response = await makeAuthorizedPost(
            `${backendUrl}/profile/connect-strava?code=${stravaCode}`,
        );
        if (response) {
          window.location.href = '/profile';
        }
      }
    })();
  }, [stravaCode]);
  return <div className="h-screen flex"><div className={'mx-auto py-10'}>Processing code...</div></div>;
}


export default ConnectStrava;
