import React from 'react';
import ServiceGrid from '../components/ServiceGrid';
import ProfileTable from '../components/ProfileTable';
import {useState, useEffect} from 'react';
import {makeAuthorizedGet} from '../utils/credentials';
import {backendUrl} from '../App';
import connectToStravaImage from '../static/images/connect-to-strava.svg';

const services = [
  {
    id: 1,
    title: 'Fitbit',
    dataKey: 'accountConnected',
    activeValues: {description: 'Connected', action: 'Disconnect'},
    inactiveValues: {
      description: 'Not connected',
      action: 'Connect',
    },
    source: true,
  },
  {
    id: 2,
    title: 'Garmin',
    dataKey: 'garminCredentialsSaved',
    activeValues: {
      description: 'Credentials are saved',
      action: 'Delete',
    },
    inactiveValues: {
      description: 'Not saved',
      action: 'Add',
    },
    source: false,
  },
  {
    id: 3,
    title: 'Strava',
    dataKey: 'stravaConnected',
    activeValues: {description: 'Connected', action: 'Disconnect'},
    inactiveValues: {
      description: 'Not connected',
      action: 'Connect',
    },
    source: false,
    overrideConnectButton: true,
    overrideConnectButtonImage: connectToStravaImage,
  },
  {
    id: 4,
    title: 'Runalyze',
    dataKey: 'runalyzeConnected',
    activeValues: {
      description: 'Connected',
      action: 'Disconnect',
    },
    inactiveValues: {
      description: 'Not connected',
      action: 'Connect',
    },
    source: false,
  },
  {
    id: 5,
    title: 'Wahoo SYSTM',
    dataKey: 'systmCredentialsSaved',
    activeValues: {
      description: 'Credentials are saved',
      action: 'Delete',
    },
    inactiveValues: {
      description: 'Credentials not saved',
      action: 'Add',
    },
    source: false,
  },
  {
    id: 6,
    title: 'TrainerRoad',
    dataKey: 'trainerRoadCredentialsSaved',
    activeValues: {
      description: 'Credentials are saved',
      action: 'Delete',
    },
    inactiveValues: {
      description: 'Credentials not saved',
      action: 'Add',
    },
    source: false,
  },
];

export default function Profile() {
  const [isLoading, setIsLoading] = useState(true);
  const [servicesActive, setServicesActive] = useState([]);
  const [profileSettings, setProfileSettings] = useState({});

  useEffect(() => {
    const fetchProfileSettings = async () => {
      const data = await makeAuthorizedGet(backendUrl + '/profile-settings');
      setProfileSettings(data.data);
      setIsLoading(false);
    };
    fetchProfileSettings();
  }, []);

  useEffect(() => {
    const servicesEnabled = services.map((service) => {
      return {
        ...service,
        active: profileSettings[service.dataKey],
      };
    });
    setServicesActive(servicesEnabled);
  }, [profileSettings]);

  return (
    <>
      {isLoading ? <div className="h-screen flex"><div className={'mx-auto py-10'}>Loading...</div></div> :
               <>
                 <ServiceGrid servicesActive={servicesActive} />
                 <ProfileTable profileSettings={profileSettings} />
               </>}
    </>
  );
}
