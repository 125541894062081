import React, {useContext} from 'react';
import {GoogleLogin} from '@react-oauth/google';
import processLogin from './authFunctions';
import useAuth from './useAuth';
import AuthContext from '../utils/AuthContext';
import {makeAuthorizedGet} from './credentials';
import {backendUrl} from '../App';
import {toast, ToastContainer} from 'react-toastify';

const ShowGoogleLogin = () => {
  const {login, logout} = useAuth();
  const {loggedIn} = useContext(AuthContext);

  return (
    <div data-cy="google-sign-in">
      <ToastContainer />
      {loggedIn ? null : (
      <GoogleLogin
        onSuccess={async (credentialResponse) => {
          const response = await processLogin(credentialResponse);
          if (response.status === 200) {
            const userResponse = await makeAuthorizedGet(`${backendUrl}/profile/get-or-create-user`, false);
            console.log(userResponse);
            if (userResponse.status !== 204) {
              toast.error('There was a problem with the server. We have been notified. Please try again.');
              logout();
            } else {
              login();
              setTimeout(() => {
                window.location.href = '/profile';
              }, 1000);
            }
          } else {
            toast.error('Google accepted the login, but there was a problem with the server. Please try again.');
          }
        }}
        onError={() => {
          toast.error('There was a problem with the Google login. Please try again.');
        }}
        useOneTap
      />
      )}
    </div>
  );
};

export default ShowGoogleLogin;
