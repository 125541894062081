import React from 'react';
import NavBar from './navigation/navbar';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Data from './pages/Data';
import Visualiser from './pages/Visualiser';
import ConnectFitbit from './pages/ConnectFitbit';
import ConnectStrava from './pages/ConnectStrava';
import About from './pages/About';
import {useState, useEffect} from 'react';
import AuthContext from './utils/AuthContext';
import {UnauthorizedContext} from './utils/UnauthorizedContext';
import Faq from './pages/Faq';
import GoogleLoginFromStatic from './pages/GoogleLogin';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import 'reactjs-popup/dist/index.css';


export const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function App() {
  const [unauthorized, setUnauthorized] = useState(false);
  const [backendErrorShown, setBackendErrorShown] = useState(false);
  const [loggedIn, setLoggedIn] = useState(() => {
    // Try to get the "loggedIn" value from localStorage
    const storedLoggedIn = sessionStorage.getItem('loggedIn');

    // If "loggedIn" is found in localStorage, parse the value and return it
    if (storedLoggedIn) {
      return JSON.parse(storedLoggedIn);
    }

    // Otherwise, default to false
    return false;
  });

  // Whenever the "loggedIn" state changes, update the "loggedIn" value in localStorage
  useEffect(() => {
    sessionStorage.setItem('loggedIn', JSON.stringify(loggedIn));
  }, [loggedIn]);
  // useEffect to blur screen and show a pop up if unauthorized
  useEffect(() => {
    if (unauthorized) {
      document.getElementById('site-main').className = 'site-main-blur';
      setTimeout(() => {
        setUnauthorized(false);
        window.location.href = '/';
      }, 3000);
    } else {
      document.getElementById('site-main').className = 'site-main';
    }
  }, [unauthorized]);
  useEffect(() => {
    axios(backendUrl + '/ping')
        .catch((_) => {
          console.log(backendErrorShown);
          if (!backendErrorShown) {
            setBackendErrorShown(true);
            toast.error('Backend server is not responding');
          }
        });
  }, []);
  return (
    <>
      <ToastContainer />
      <div>
        <UnauthorizedContext.Provider value={{unauthorized, setUnauthorized}}>
          <AuthContext.Provider value={{loggedIn, setLoggedIn}}>
            {unauthorized && (
              <div className="popup-container">
                <div className="popup">
                  You are not authorized to view this page. Please log in.
                </div>
              </div>
            )}
            <section className="site-main" id="site-main">
              <NavBar />
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="data" element={<Data />} />
                  <Route path="faq" element={<Faq />} />
                  <Route path="visualiser" element={<Visualiser />} />
                  <Route path="fitbit" element={<ConnectFitbit />} />
                  <Route path="strava-response" element={<ConnectStrava />} />
                  <Route path="about" element={<About />} />
                  <Route path="google-login" element={<GoogleLoginFromStatic />} />
                </Routes>
              </BrowserRouter>
            </section>
          </AuthContext.Provider>
        </UnauthorizedContext.Provider>
      </div>
    </>
  );
}
