import React from 'react';
import ServiceCard from './ServiceCard';

const ServiceGrid = (props) => {
  const servicesActive = props.servicesActive;
  const isAdmin = JSON.parse(localStorage.getItem('user-info'))?.is_admin;
  return (
    <section>
      <div className="container mx-auto px-6 pt-12 flex justify-center items-center">
        <div className="flex flex-wrap justify-center ServiceCardGrid">
          <div className="weight-sources">
            <div className="grid justify-center">
              <h2>Sources</h2>
            </div>
            <div className="flex flex-row flex-wrap justify-center">
              {servicesActive.filter((service) => service.source).map((service) => {
                return (
                  <div key={service.id} className="">
                    <div className="ServiceCard">
                      <ServiceCard {...service} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-6 pt-12 flex justify-center items-center">
        <div className="flex flex-wrap justify-center ServiceCardGrid">
          <div className="weight-destinations">
            <div className="grid justify-center">
              <h2>Destinations</h2>
            </div>
            <div className="flex flex-row flex-wrap justify-center">
              {servicesActive.filter((service) => service.source === false && service.title !== 'TrainerRoad').map((service) => {
                return (
                  <div key={service.id} className="">
                    <div className="ServiceCard">
                      <ServiceCard {...service} />
                    </div>
                  </div>
                );
              })}
              {isAdmin && servicesActive.filter((service) => service.source === false && service.title === 'TrainerRoad').map((service) => {
                return (
                  <div key={service.id} className="">
                    <div className="ServiceCard">
                      <ServiceCard {...service} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceGrid;
