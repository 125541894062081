import React from 'react';
import WeightTable from '../components/WeightTable';

const Data = () => {
  return (
    <div>
      <WeightTable />
    </div>
  );
};

export default Data;
