import axios from 'axios';

axios.defaults.timeout = 5000;

export function getBearerFromSession() {
  const bearer = sessionStorage.getItem('bearer');
  // console.log(bearer);
  return bearer;
}

export function storeBearerInSession(bearer) {
  sessionStorage.setItem('bearer', bearer);
}

function handleUnauthorized() {
  sessionStorage.removeItem('bearer');
  sessionStorage.setItem('loggedIn', false);
  window.location.href = '/';
}

function handleAuthorized() {
  sessionStorage.setItem('loggedIn', true);
}

export async function makeAuthorizedGet(url, raiseError = true) {
  try {
    const bearer = getBearerFromSession();
    const authHeader = {Authorization: `Bearer ${bearer}`};

    const config = {
      headers: authHeader,
      withCredentials: true,
    };
    const response = await axios.get(url, config);
    handleAuthorized();
    return response;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      handleUnauthorized();
      return 'UNAUTHORIZED';
    }
    if (raiseError) {
      throw error;
    } else {
      return error;
    }
  }
}

export async function makeAuthorizedDelete(url) {
  try {
    const bearer = getBearerFromSession();
    const authHeader = {Authorization: `Bearer ${bearer}`};

    const config = {
      headers: authHeader,
      withCredentials: true,
    };
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      handleUnauthorized();
      return 'UNAUTHORIZED';
    }
    throw error;
  }
}

export async function makeAuthorizedPost(url, data) {
  try {
    const bearer = getBearerFromSession();
    const authHeader = {Authorization: `Bearer ${bearer}`};

    const config = {
      headers: authHeader,
      withCredentials: true,
    };
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      handleUnauthorized();
      return 'UNAUTHORIZED';
    }
    throw error;
  }
}
