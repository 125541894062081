import React, {useState} from 'react';
import {backendUrl} from '../App';
import {getBearerFromSession} from '../utils/credentials';
import axios from 'axios';
import {toast} from 'react-toastify';


function UsernamePasswordForm(props) {
  const [formData, setFormData] = useState({});
  const {notifySuccess, notifyPending, notifyFailure} = props;


  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setFormData({...formData, [name]: value});
  };

  const postCredentials = async (url, data, serviceName) => {
    const bearer = getBearerFromSession();
    const authHeader = {Authorization: `Bearer ${bearer}`};
    const config = {
      headers: authHeader,
      withCredentials: true,
    };
    axios.post(url, data, config)
        .then((response) => {
          if (response.status === 201) {
            toast.dismiss();
            notifySuccess(`Successfully connected to ${serviceName}`);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        })
        .catch((error) => {
          toast.dismiss();
          if (error.response.status === 400) {
            notifyFailure('An error occurred, probably due to incorrect username or password');
          } else if (error.response.status === 429) {
            notifyFailure('Service is currently rate limited by Garmin, please try again later');
          }
        });
  };

  const handleSubmit = (event) => {
    const {service} = props;
    event.preventDefault();
    setShowPopup(false);
    notifyPending('Connecting to ' + service + '...');

    if (service === 'Wahoo SYSTM') {
      postCredentials(backendUrl + '/profile/form-submit/systm', formData, service);
    } else {
      postCredentials(backendUrl + '/profile/form-submit/' + service, formData, service);
    }
  };
  const setShowPopup = props.setShowPopup;

  return (
    <div className="overlay">
      <div className="popup-container">
        <div className="popup">
          <button
            className="close-popup"
            onClick={() => {
              setShowPopup(false);
            }}
          >
            X
          </button>
          <form onSubmit={handleSubmit}>
            {props.service === 'Garmin' && (
              <>
                <h2>Enter Garmin username and password</h2>
                <div>
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    required="required"
                    onChange={handleInputChange}
                    className="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    required="required"
                    onChange={handleInputChange}
                    className="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </>
            )}
            {(props.service === 'Wahoo SYSTM' || props.service === 'TrainerRoad') && (
              <>
                <h2>Enter {props.service} username and password</h2>
                <div>
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    required="required"
                    onChange={handleInputChange}
                    className="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    required="required"
                    onChange={handleInputChange}
                    className="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </>
            )}
            {props.service === 'Runalyze' && (
              <>
                <h2>Enter Runalyze API key</h2>
                <div>
                  <input
                    type="text"
                    name="password"
                    placeholder="API Key"
                    required="required"
                    onChange={handleInputChange}
                    className="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </>
            )}
            <div>
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UsernamePasswordForm;
