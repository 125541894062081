export function parseDate(dt) {
  const date = new Date(dt);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hour = ('0' + date.getHours()).slice(-2);
  const minute = ('0' + date.getMinutes()).slice(-2);
  return `${day}/${month}/${year} ${hour}:${minute}`;
}

export function sortArray(key, data, desc = true) {
  return data.sort((a, b) => {
    const compareA = a[key] == null ? 0 : a[key];
    const compareB = b[key] == null ? 0 : b[key];

    if (desc) {
      if (compareA < compareB) {
        return 1;
      }
      if (compareA > compareB) {
        return -1;
      }
      return 0;
    } else {
      if (compareA > compareB) {
        return 1;
      }
      if (compareA < compareB) {
        return -1;
      }
      return 0;
    }
  });
}
