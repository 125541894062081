import React, {useEffect, useState} from 'react';
import {Fragment} from 'react';
import {Disclosure, Menu, Transition} from '@headlessui/react';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import {useContext} from 'react';
import AuthContext from '../utils/AuthContext';
import useAuth from '../utils/useAuth';

const navigation = [
  {name: 'Home', href: '/', current: false, requiresLogin: false},
  {name: 'Profile', href: '/profile', current: false, requiresLogin: true},
  {name: 'Data', href: '/data', current: false, requiresLogin: true},
  {
    name: 'Visualiser',
    href: '/visualiser',
    current: false,
    requiresLogin: true,
  },
  {name: 'FAQ', href: '/faq', current: false, requiresLogin: false},
  {name: 'About', href: '/about', current: false, requiresLogin: false},
  {name: 'Status', href: 'https://stats.uptimerobot.com/Ccomn7VYTf', current: false, requiresLogin: false},
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function NavBar() {
  const {loggedIn} = useContext(AuthContext);
  const {logout} = useAuth();
  const [nameLetter, setNameLetter] = useState('');

  const getFirstLetterNameFromLocalStorage = () => {
    let userInfo;
    try {
      userInfo = JSON.parse(localStorage.getItem('user-info'));
    } catch (error) {
      localStorage.removeItem('user-info');
      userInfo = null;
    }
    if (userInfo) {
      if (userInfo.given_name) {
        return userInfo.given_name.charAt(0).toUpperCase();
      } else if (userInfo.email) {
        return userInfo.email.charAt(0).toUpperCase();
      }
      return '';
    }
  };
  useEffect(() => {
    setNameLetter(getFirstLetterNameFromLocalStorage());
  }, []);

  return (
    <Disclosure as="nav" className="navbar" data-cy="navbar">
      {({open}) => (
        <>
          <div className="mx-auto max-w-7xl px-2 md:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-200 hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <div style={{color: 'white', fontWeight: 'bold'}}>
                    <a href="/" className="hover:text-gray-200">
                      WeightSync
                    </a>
                  </div>
                </div>
                <div className="hidden md:ml-6 md:block">
                  <div className="flex space-x-4" data-cy="navbar-item">
                    {navigation.map((item) =>
                      item.requiresLogin && !loggedIn ? null : (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ?
                              'bg-gray-900 text-white' :
                              'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium text-white',
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ),
                    )}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    {loggedIn ? (
                      <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        <h3 className="profile-logo">
                          {nameLetter}
                        </h3>
                      </Menu.Button>
                    ) : (
                      <a href="/">
                        <h3 style={{color: 'white'}} data-cy="sign-in-link">Sign In</h3>
                      </a>
                    )}
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        <a
                          href="/profile"
                          className="block px-4 py-2 text-sm text-gray-700"
                        >
                          Profile
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a
                          href="/"
                          className="block px-4 py-2 text-sm text-gray-700"
                          onClick={() => {
                            logout();
                          }}
                        >
                          Sign out
                        </a>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) =>
                item.requiresLogin && !loggedIn ? null : (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ?
                        'bg-gray-900 text-white' :
                        'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium text-white',
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ),
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
